import React from "react";

function Description() {
    return <p>
        Benvenuti.<br/>
        Da oltre <strong>50 anni</strong>, siamo presenti nel mercato dell'arredamento con un'ampia gamma di
        proposte per soddisfare e migliorare la qualità della vostra vita; selezioniamo i migliori materiali e le più
        affermate marche <strong>100% <span style={{"color": "green"}}>Made</span> <span
        style={{"color": "white"}}>in</span> <span style={{"color": "red"}}>Italy</span></strong>.<br/> La continua
        ricerca di innovazione, il rispetto dell'ambiente e della sicurezza per la vostra salute hanno permesso alla
        nostra azienda di avere una posizione centrale nel settore.<br/> Personale qualificato sarà a vostra
        disposizione e grazie alla tecnologia <strong>3D</strong> daremo forma al tuo stile e all'ambiente che
        sogni.<br/> Il meglio del design contemporaneo o classico ad un prezzo <strong>su misura</strong>.<br/> <strong>Affidabilità,
        creatività, serietà, assistenza nel tempo</strong> sono i nostri migliori biglietti da visita.<br/> Venite a
        trovarci. Vi aspettiamo.
    </p>
}

function LoremIpsum() {
    return "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris tempus eget sem non gravida. Integer sed metus eget dui ornare ullamcorper. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer dapibus mi vel ornare tincidunt. Morbi ut tempor felis. Pellentesque quis sollicitudin ligula. Nullam nec vehicula augue, quis vehicula justo. Maecenas ultricies nisi dui. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse at elementum nunc, et ornare urna. Vestibulum sodales vehicula ligula, et egestas nisi ultrices non. Aenean malesuada, odio sit amet consectetur porta, est diam tincidunt ipsum, quis pretium dolor lacus et dolor. Nullam hendrerit ligula dui, condimentum pellentesque mauris pretium sit amet. Pellentesque gravida et mauris in suscipit."
}


export {
    Description,
    LoremIpsum
}
