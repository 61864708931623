import React from "react";

import "./Home.min.css";
import {baseTitle} from "../App/App";
import BannerPromozioni from "./BannerPromozioni";
import HomeSlideshow from "./HomeSlideshow";
import BannerCategorie from "./BannerCategorie";
import {scrollToTop} from "../../common";
import Descrizione from "./Descrizione";
import Servizi from "./Servizi";
import Marchi from "./Marchi";


function Home() {
    document.title = baseTitle;
    scrollToTop()

    return <div className={"Home"}>
        <HomeSlideshow/>
        {/*<Benvenuto/>*/}
        <Descrizione/>
        <Servizi/>
        <Marchi/>
        <BannerPromozioni/>
        <BannerCategorie/>
    </div>
}

export default Home;
