import React from "react";

import "./Promotions.min.css";
import {basePath, baseTitle} from "../App/App";
import API from "../../API";
import {Link} from "react-router-dom";


class Promotions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promotions: {}
        }
    }

    componentWillMount() {
        document.title = "Promozioni - " + baseTitle;

        API.getPromozioni(true)
            .then(value => {
                this.setState({promotions: value})
            })
            .catch(reason => {
                console.error(reason)
            });
    }

    render() {
        return <div className={"Promotions"}>
            <h1>Promozioni</h1>
            <PromoViewer promo={this.state.promotions}/>
        </div>;
    }
}

function PromoViewer(props) {
    return <div className={"PromoViewer"}>
        <NowPromo promo={props.promo}/>
        <h2>Promozioni passate</h2>
        <OldPromo promo={props.promo}/>
    </div>

}

function NowPromo(props) {
    if (props.promo && Object.keys(props.promo).length > 0) {
        return <div className={"NowPromo"}>
            {Object.keys(props.promo)
                .filter(key => {
                    const promo = props.promo[key];
                    return !promo.scadenza || (new Date() <= new Date(promo.scadenza * 1000))
                })
                .map(key => {
                    const promo = props.promo[key];
                    return <Promo key={promo.id} id={promo.id} title={promo.titolo} desc={promo.descrizione}
                                  scad={promo.scadenza}/>
                })}
        </div>
    } else {
        return <div className={"NowPromo"}>Non ci sono promozioni attive.</div>
    }
}

function OldPromo(props) {
    if (props.promo && Object.keys(props.promo).length > 0) {
        return <div className={"OldPromo"}>
            {Object.keys(props.promo)
                .filter(key => {
                    const promo = props.promo[key];
                    return promo.scadenza && new Date() > new Date(promo.scadenza * 1000)
                })
                .map(key => {
                    const promo = props.promo[key];
                    return <Promo key={promo.id} id={promo.id} title={promo.titolo} desc={promo.descrizione}
                                  scad={promo.scadenza}/>
                })}
        </div>
    } else {
        return <div className={"OldPromo"}>Non ci sono altre promozioni</div>
    }
}

class Promo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            images: {}
        }
    }

    componentWillMount() {
        API.getImmaginiPromozione(this.props.id, true)
            .catch(reason => {
                console.error(reason)
            })
            .then(images => {
                Object.keys(images).forEach(key => {
                    API.getImageById(key)
                        .then(img => {
                            images[key] = img.path;
                            this.setState({images: images});
                        })
                        .catch(reason => {
                            console.error(reason)
                        });
                });
            });
    }

    render() {
        return <Link className={"Promo"}
                     to={`${basePath}/promo/id/${this.props.id}`}
                     style={{
                         backgroundImage: `linear-gradient(rgba(0,0,0,0.0),rgba(0,0,0,0.30)),url(${Object.values(this.state.images)[0]})`
                     }}>

            <h3>{this.props.title}</h3>
            {this.props.scad ? <div
                className={"Scadenza"}>Scadenza: {new Date(this.props.scad * 1000 + 120 * 60 * 1000).toLocaleDateString()}</div> : null}
        </Link>
    }
}

export default Promotions;