import React from "react";

import "./HomeSlideshow.min.css"
import imgLogoMain from "../../res/img/sezioni_logo/logo_main.gif";
import imgLogoLeft from "../../res/img/sezioni_logo/logo_left.gif";
import imgLogoRight from "../../res/img/sezioni_logo/logo_right.gif";
import imgPrincipale from "../../res/img/principale.jpg";
import imgZonaGiorno from "../../res/img/zona_giorno.jpg";
import imgZonaNotte from "../../res/img/zona_notte.jpg";
import cuoriArredare from "../../res/img/cuori.png";

import {Carousel} from 'react-responsive-carousel';

const slides = [
    {
        class: "logo",
        image: imgPrincipale,
        content: [
            <div>
                <img src={imgLogoMain} className={"logo_main fade-in"} alt={"sezione_logo"}/>
                <img src={imgLogoLeft} className={"logo_left fade-in"} alt={"sezione_logo"}/>
                <img src={imgLogoRight} className={"logo_right fade-in"} alt={"sezione_logo"}/>
            </div>
        ]
    },
    {
        class: "two-texts",
        image: imgZonaGiorno,
        content: [<h2 style={{
            backgroundImage: `url(${cuoriArredare})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "left center"
        }}>Arredare casa</h2>, <h3>Giorno e notte</h3>]
    },
    {
        class: "two-texts",
        image: imgZonaNotte,
        content: [<h2 style={{
            backgroundImage: `url(${cuoriArredare})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "left center"
        }}>Arredare casa</h2>, <h3>Giorno e notte</h3>]
    },
    // {image: imgFamiglia, content: <Description/>},
];

function HomeSlideshow() {
    return <div className={"HomeSlideshow"}>
        <Slideshow contents={slides}/>
    </div>
}


function Slideshow(props) {
    if (props.contents && props.contents.length > 0) {
        return <Carousel
            autoPlay={true}
            dynamicHeight={false}
            infiniteLoop={true}
            interval={5000}
            showArrows={true}
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
            statusFormatter={(cur, tot) => {
                return `${cur} di ${tot}`
            }}
            stopOnHover={true}
            swipeScrollTolerance={100}
            transitionTime={500}
            useKeyboardArrows={false}>
            {props.contents.map((e, k) => {

                return <div
                    className={"home-slide-container" + (e.class ? ` ${e.class}` : "")}
                    key={k}
                    style={{
                        backgroundImage: `url(${e.image})`
                    }}>
                    {e.content}
                </div>
            })}
        </Carousel>
    } else {
        return <div/>
    }
}


export default HomeSlideshow;
