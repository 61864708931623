import Axios from "axios";

import CM from "./ConfigManager";

let _outletCategoryId = null;

const mapArrayToObjectById = arr => {
    let res = {};
    arr.forEach(o => {
        res[o.id] = o;
    });
    return res;
};

class API {

    static updateOutletCategoryId() {
        if (_outletCategoryId === null) {
            API.getTags()
                .then(value => {
                    _outletCategoryId = value.filter(o => {
                        return o.tag === "outlet"
                    })[0]["id"];
                })
                .catch(reason => {
                    console.error(reason)
                });

        }
    }

    static getPromozioni(asObject = false) {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(host + "/promotions")
                .then(value => {
                    if (asObject === false) {
                        resolve(value.data.message);
                    } else {
                        resolve(mapArrayToObjectById(value.data.message));
                    }
                })
                .catch(reason => reject(reason))

        })
    }

    /**
     *
     * @param {number} n
     * @param {boolean} [asObject]
     * @returns {Promise<any>}
     */
    static getPromozioniLastN(n = 3, asObject = false) {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(host + "/promotions/last/" + n)
                .then(value => {
                    if (asObject === false) {
                        resolve(value.data.message);
                    } else {
                        resolve(mapArrayToObjectById(value.data.message));
                    }
                })
                .catch(reason => {
                    reject(reason)
                });
        })
    }

    /**
     * @param id - Id della promozione
     * @param asObject
     */
    static getImmaginiPromozione(id, asObject = false) {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(`${host}/promotions/${id}/images`)
                .then(value => {
                    if (asObject === false) {
                        resolve(value.data.message.map(o => {
                            return o.id
                        }));
                    } else {
                        resolve(mapArrayToObjectById(value.data.message))
                    }
                })
                .catch(reason => {
                    reject(reason);
                });
        });
    }

    /**
     * @param id - Id del prodotto
     * @param asObject - if true returns the data as a key-value dictionary instead of an array
     */
    static getImmaginiProdotto(id, asObject = false) {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(`${host}/products/${id}/images`)

                .then(value => {
                    if (asObject) {
                        let res = {};
                        value.data.message
                            .map(o => {
                                return o.id
                            })
                            .forEach(id => {
                                res[id] = id;
                            });
                        resolve(res);
                    } else {
                        resolve(value.data.message.map(o => {
                            return o.id
                        }));
                    }
                })
                .catch(reason => {
                    reject(reason);
                });

        });
    }

    static getCategories(asObject = false) {
        return new Promise((resolve, reject) => {
            API.getTags()
                .then(value => {
                    if (asObject === false) {
                        resolve(value.filter(v => {
                            return !["moderno", "classico", "outlet"].includes(v.tag)
                        }))
                    } else {
                        resolve(mapArrayToObjectById(value.filter(v => {
                            return !["moderno", "classico", "outlet"].includes(v.tag)
                        })))
                    }

                })
                .catch(reason => {
                    reject(reason)
                });

        })
    }

    static getImageById(id) {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(`${host}/images/${id}`)
                .then(value => {
                    resolve(value.data.data);
                })
                .catch(reason => {
                    reject(reason)
                });
        })
    }

    static getTags() {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(host + "/tags")
                .then(value => {
                    resolve(value.data.message);
                })
                .catch(reason => {
                    reject(reason)
                });
        })
    }

    static getTagProdottoById(idProdotto) {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(`${host}/products/${idProdotto}/tags`)
                .then(value => {
                    resolve(value.data.message);
                })
                .catch(reason => {
                    reject(reason)
                });
        });
    }

    static getProdottiByCategorie(filterList) {

        filterList = filterList.filter(o => {
            return o !== null && o !== undefined;
        });
        filterList = filterList.map(o => {
            return parseInt(o, 10);
        });
        if (filterList && filterList[0] !== 0) {
            return new Promise((resolve, reject) => {

                const host = CM.getAPIHost();
                Axios.get(`${host}/products/tags/${filterList[0]}`)
                    .then(value => {
                        const products = value.data.message;
                        Promise.all(products.map(p => {
                            return API.getTagProdottoById(p.id);
                        }))
                            .then(produtsTags => {
                                produtsTags = produtsTags.map(pt => {
                                    return pt.map(list => {
                                        return list.id
                                    });
                                });
                                products.map((p, i) => {
                                    p.tags = produtsTags[i];
                                    return p;
                                });

                                // Filter out outlet products
                                const nonOutlet = (products.filter((p, i) => {
                                    return !p.tags.includes(_outletCategoryId)
                                }));

                                console.log(nonOutlet)
                                const filtered = (nonOutlet.filter((p, i) => {
                                    let status = true;
                                    filterList.forEach(t => {
                                        status = status && p.tags.includes(t)
                                    });
                                    return status;
                                }));
                                console.log(filtered)
                                resolve(filtered);
                            })
                            .catch(reason => {
                                reject(reason)
                            })
                    })
                    .catch(reason => {
                        reject(reason)
                    });
            });

        } else {
            filterList[0] = null;
            filterList = filterList.filter(o => {
                return o !== null && o !== undefined;
            });

            return new Promise((resolve, reject) => {
                API.getProdotti()
                    .then(value => {
                        const products = value;
                        Promise.all(products.map(p => {
                            return API.getTagProdottoById(p.id);
                        }))
                            .then(produtsTags => {
                                produtsTags = produtsTags.map(pt => {
                                    return pt.map(list => {
                                        return list.id
                                    });
                                });
                                products.forEach((p, i) => {
                                    p.tags = produtsTags[i];
                                });

                                // Filter out outlet products
                                const nonOutlet = (products.filter((p, i) => {
                                    return !p.tags.includes(_outletCategoryId)
                                }));

                                const filtered = (nonOutlet.filter((p, i) => {
                                    let status = true;
                                    filterList.forEach(t => {
                                        status = status && p.tags.includes(t) && !p.tags.includes(_outletCategoryId);
                                    });
                                    return status;
                                }));
                                resolve(filtered);
                            })
                            .catch(reason => {
                                reject(reason)
                            })
                    })
                    .catch(reason => {
                        reject(reason)
                    });
            })
        }
    }

    static getOutletByCategorie(filterList) {

        filterList = filterList.filter(o => {
            return o !== null && o !== undefined;
        });
        filterList = filterList.map(o => {
            return parseInt(o, 10);
        });
        if (filterList && filterList[0] !== 0) {
            return new Promise((resolve, reject) => {
                const host = CM.getAPIHost();
                Axios.get(`${host}/products/outlet/tags/${filterList[0]}`)
                    .then(value => {
                        const products = value.data.message;
                        Promise.all(products.map(p => {
                            return API.getTagProdottoById(p.id);
                        }))
                            .then(produtsTags => {
                                produtsTags = produtsTags.map(pt => {
                                    return pt.map(list => {
                                        return list.id
                                    });
                                });
                                products.map((p, i) => {
                                    p.tags = produtsTags[i];
                                    return p;
                                });
                                const filtered = (products.filter((p, i) => {
                                    console.log(filterList, p.tags);
                                    let status = true;
                                    filterList.forEach(t => {
                                        console.log(t)
                                        status = status && p.tags.includes(t)
                                    });
                                    return status;
                                }));
                                resolve(filtered);
                            })
                            .catch(reason => {
                                reject(reason)
                            })
                    })
                    .catch(reason => {
                        reject(reason)
                    });
            });

        } else {
            filterList[0] = null;
            filterList = filterList.filter(o => {
                return o !== null && o !== undefined;
            });

            return new Promise((resolve, reject) => {
                API.getOutlet()
                    .then(value => {
                        const products = value;
                        Promise.all(products.map(p => {
                            return API.getTagProdottoById(p.id);
                        }))
                            .then(produtsTags => {
                                produtsTags = produtsTags.map(pt => {
                                    return pt.map(list => {
                                        return list.id
                                    });
                                });
                                products.map((p, i) => {
                                    p.tags = produtsTags[i];
                                    return p;
                                });
                                const filtered = (products.filter((p, i) => {
                                    let status = true;
                                    filterList.forEach(t => {
                                        status = status && p.tags.includes(t)
                                    });
                                    return status;
                                }));
                                resolve(filtered);
                            })
                            .catch(reason => {
                                reject(reason)
                            })
                    })
                    .catch(reason => {
                        reject(reason)
                    });
            })
        }
    }

    static getOutlet(asObject = false) {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(`${host}/products/outlet`)
                .then(value => {
                    if (asObject === false) {
                        resolve(value.data.message)
                    } else {
                        resolve(mapArrayToObjectById(value.data.message))
                    }
                })
                .catch(reason => {
                    reject(reason)
                });
        })
    }

    static getOutletByCategoria(filter, asObject = false) {
        if (_outletCategoryId === null) {
            API.updateOutletCategoryId();
        }
        if (filter !== 0) {
            return new Promise((resolve, reject) => {
                const host = CM.getAPIHost();
                Axios.get(`${host}/products/outlet/tags/${filter}`)
                    .then(value => {
                        if (asObject === false) {
                            resolve(value.data.message)
                        } else {
                            resolve(mapArrayToObjectById(value.data.message))
                        }
                    })
                    .catch(reason => {
                        reject(reason);
                    });

            });
        } else {
            return API.getOutlet(asObject)
        }

    }

    static getProdotti(asObject = false) {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(`${host}/products`)
                .then(value => {
                    if (asObject === false) {
                        resolve(value.data.message)
                    } else {
                        resolve(mapArrayToObjectById(value.data.message))
                    }
                })
                .catch(reason => {
                    reject(reason)
                });
        })
    }

    static getProdottoById(id, asObject = false) {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(`${host}/products/${id}`)
                .then(value => {
                    if (asObject === false) {
                        resolve(value.data.message)
                    } else {
                        resolve(mapArrayToObjectById(value.data.message))
                    }
                })
                .catch(reason => {
                    reject(reason)
                });
        });
    }

    static getPromozioneById(id, asObject = false) {
        return new Promise((resolve, reject) => {
            const host = CM.getAPIHost();
            Axios.get(`${host}/promotions/${id}`)
                .then(value => {
                    if (asObject === false) {
                        resolve(value.data.message)
                    } else {
                        resolve(mapArrayToObjectById(value.data.message))
                    }
                })
                .catch(reason => {
                    reject(reason)
                });
        })
    }

    /***
     * @typedef {object} PutProdottoById
     * @param [titolo] string
     * @param [descrizione] string
     * @param [autore] string
     */
    /**
     * @param o PutProdottoById
     * @param id number
     */
    static putProdottoById(o, id) {
        const params = new URLSearchParams();
        if (o.titolo) {
            params.append("Titolo", o.titolo);
        }
        if (o.descrizione) {
            params.append("Descrizione", o.descrizione);
        }
        if (o.autore) {
            params.append("Autore", o.autore);
        }
        return Axios.put(
            `${CM.getAPIHost()}/products/${id}`,
            params,
            {
                headers:
                    {token: localStorage.getItem(CM.authTokenString)}
            }
        );
    }

    /***
     * @typedef {object} PutPromoById
     * @param [titolo] string
     * @param [descrizione] string
     * @param [autore] string
     * @param [scadenza] Date
     */
    /**
     * @param o PutProdottoById
     * @param id number
     */
    static putPromoById(o, id) {
        const params = new URLSearchParams();
        if (o.titolo) {
            params.append("Titolo", o.titolo);
        }
        if (o.descrizione) {
            params.append("Descrizione", o.descrizione);
        }
        if (o.autore) {
            params.append("Autore", o.autore);
        }
        if (o.scadenza) {
            params.append("Scadenza", o.scadenza.toJSON().slice(0, 19).replace('T', ' ').toString());
        }
        return Axios.put(
            `${CM.getAPIHost()}/promotions/${id}`,
            params,
            {
                headers:
                    {token: localStorage.getItem(CM.authTokenString)}
            }
        );
    }

    /**
     * number id - ID of the image
     * @param id
     */
    static deleteImage(id) {
        return Axios.delete(
            `${CM.getAPIHost()}/images/${id}`,
            {
                headers:
                    {token: localStorage.getItem(CM.authTokenString)}
            }
        );
    }

    /**
     * @param images - FileList file list
     */
    static uploadImages(images) {
        let formData = new FormData();
        const list = Array.from(images);
        list.forEach((image, i) => {
            formData.append("Image" + i, image);
        });
        return Axios.post(
            `${CM.getAPIHost()}/images/`,
            formData,
            {
                headers:
                    {token: localStorage.getItem(CM.authTokenString)}
            }
        );
    }

    /**
     * Aggiorna le immagini di un prodotto
     * @param idProd number
     * @param idsImage
     */
    static putProductImages(idProd, idsImage) {
        let formData = new FormData();
        formData.append("Images", idsImage.map(n => {
            return n.toString()
        }).join(","));
        return Axios.put(
            `${CM.getAPIHost()}/products/${idProd}/images`,
            formData,
            {
                headers:
                    {token: localStorage.getItem(CM.authTokenString)}
            }
        );
    }

    /**
     * Aggiorna le immagini di una promozione
     * @param idPromo
     * @param idsImage
     * @returns {AxiosPromise<any>}
     */
    static putPromoImages(idPromo, idsImage) {
        let formData = new FormData();
        formData.append("Images", idsImage.map(n => {
            return n.toString()
        }).join(","));
        return Axios.put(
            `${CM.getAPIHost()}/promotions/${idPromo}/images`,
            formData,
            {
                headers:
                    {token: localStorage.getItem(CM.authTokenString)}
            }
        );
    }
}

CM.testConnection()
    .then((value) => {
        console.log(`API reached :D`);
    })
    .catch(reason => {
        console.error("I can't connect to the API :(");
        console.error(reason);
    });

API.updateOutletCategoryId();

export default API