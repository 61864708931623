import Axios from "axios";

import config from "./config.json";

class ConfigManager {

    static state = {
        APIHost: config.API_URL,
        working: true,
        lastCheck: null
    };

    static getAPIHost() {
        return this.state.APIHost
    }

    static testConnection() {

        return new Promise((resolve, reject) => {
            Axios.get(ConfigManager.state.APIHost)
                .then((value) => {
                    this.state.working = true;
                    resolve(value);
                })
                .catch(reason => {
                    this.state.working = false;
                    reject(reason);
                });
        });
    }
}

export default ConfigManager;