import React from "react"
import "./Descrizione.min.css"
import imgFamiglia from "../../res/img/famiglia.jpg";
import {Description} from "./texts";

function Descrizione() {
    return <div className={"Descrizione"}
                style={{backgroundImage: `linear-gradient(rgba(199,220,229,0.7),rgba(199,220,229,0.8)),url(${imgFamiglia})`}}>
        <Description/>
    </div>
}

export default Descrizione
