import React from "react";
import {Link} from "react-router-dom";

import "./Navbar.min.css";
import Logo from "../../res/img/logo.gif";
import {scrollToTop} from "../../common";
import {basePath} from "../App/App";

function Navbar() {
    return <nav className={"Navbar"}>
        <ul>
            <li><Link to={`${basePath}/`} title={"Home"} onClick={() => scrollToTop()}>
                <img className={"Logo"} src={Logo}
                     alt={"Logo mobilificio"}/></Link></li>
        </ul>
        <ul>
            <li><Link to={`${basePath}/products`} title={"Prodotti"} onClick={() => scrollToTop()}>Prodotti</Link></li>
            <li><Link to={`${basePath}/promo`} title={"BannerPromozioni"}
                      onClick={() => scrollToTop()}>Promozioni</Link></li>
            <li><Link to={`${basePath}/outlet`} title={"Outlet"} onClick={() => scrollToTop()}>Outlet</Link></li>
            <li><Link to={`${basePath}/info`} title={"Informazioni"} onClick={() => scrollToTop()}>Informazioni</Link>
            </li>
        </ul>
    </nav>
}

export default Navbar