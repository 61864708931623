import React from "react";
import "./Servizi.min.css";
import imgServizi from "../../res/img/sfondoServizi1.jpg";
import svgCash from "../../res/svg/cash.svg";
import svgCrop from "../../res/svg/crop.svg";
import svgRuler from "../../res/svg/ruler.svg";
import svgWrench from "../../res/svg/wrench.svg";
import svgDelivery from "../../res/svg/delivery.svg";
import svgAssistance from "../../res/svg/assistance.svg";
import svgCurrency from "../../res/svg/currency.svg";
import {Link} from "react-router-dom";
import {basePath} from "../App/App";


function Servizi() {
    return <div className={"Servizi"}
                style={{backgroundImage: `linear-gradient(rgba(199,220,229,0.7),rgba(199,220,229,0.8)),url(${imgServizi})`}}>
        <h2>I nostri servizi</h2>
        <ul className={"ListaServizi"}>
            <li><img src={svgCash} alt={"Preventivi gratuiti"}/>
                <h3>Preventivi gratuiti</h3>
            </li>
            <li><img src={svgCrop} alt={"Consulenza e progettazione 3D"}/>
                <h3>Consulenza e progettazione 3D</h3>
            </li>
            <li><img src={svgRuler} alt={"Rilevamenti misure a domicilio"}/>
                <h3>Rilevamenti misure a domicilio</h3>
            </li>
            <li><img src={svgWrench} alt={"Posizionamento elettrico e idraulico"}/>
                <h3>Posizionamento elettrico e idraulico</h3>
            </li>
            <li><img src={svgDelivery} alt={"Consegna da personale specializzato"}/>
                <h3>Consegna da personale specializzato</h3>
            </li>
            <li><img src={svgAssistance} alt={"Garanzia e assistenza post-vendita"}/>
                <h3>Garanzia e assistenza post-vendita</h3>
            </li>
            <li>
                <div id={"tasso-zero"}><span>0</span><span>%</span></div>
                <h3>Finanziamenti personalizzati anche a tasso zero</h3>
            </li>
            <li><img src={svgCurrency} alt={"Offerte speciali sui prodotti esposti"}/>
                <h3>Offerte speciali sui prodotti esposti</h3>
                <Link to={`${basePath}/outlet`}>Vedi outlet</Link>
            </li>
        </ul>
    </div>
}

export default Servizi
