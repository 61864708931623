import React from "react";

import "./Informazioni.min.css";
import {baseTitle} from "../App/App";

import imgEsterno from "../../res/img/informazioni/esterno.jpg"
import imgCartello from "../../res/img/informazioni/cartello.jpg"
import {scrollToTop} from "../../common";

class Informazioni extends React.Component {

    componentWillMount() {
        document.title = "Informazioni - " + baseTitle;
        scrollToTop()
    }

    render() {
        return <div id={"Informazioni"} className={"Informazioni"}>
            <Contatti/>
            <Mappa/>
        </div>
    }
}

function Contatti(props) {
    return <div className={"Contatti"}>
        <div className={"Etichetta"}>
            <h2>Contatti</h2>
        </div>
        <div className={"Contenuto"}>
            <dl>
                <dt>Telefono:</dt>
                <dd><a className={"visible"} href={"tel:0425641808"}>0425.641808</a></dd>
                <dt>Email:</dt>
                <dd><a className={"visible"} href={"mailto:arreda@mobilialsantuario.it"}>arreda@mobilialsantuario.it</a>
                </dd>
                <dt>Facebook:</dt>
                <dd><a className={"visible"}
                       href={"https://www.facebook.com/Arredamenti-al-Santuario-178564222264713/"}>Arredamenti al
                    Santuario</a></dd>
            </dl>
            <img src={imgEsterno} alt={"Foto Via Santuario, ufficio ed esposizione"}/>
        </div>
    </div>
}

function Mappa(props) {
    return <div className={"Mappa"}>
        <div className={"Etichetta"}>
            <h2>Dove ci trovate</h2>
        </div>
        <div className={"Contenuto"}>
            <p>La nostra esposizione si trova a Lendinara (RO) in Via Santuario, 46.</p>
            <ul>
                <li>Siamo aperti dal Lunedì al Sabato</li>
                <li>Mattina: 8:30-12:30</li>
                <li>Pomeriggio: 15:30-19:30</li>
            </ul>
            <div className={"map"}>
                <iframe
                    title={"Google Map"}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3488.3333981345727!2d11.603531315781202!3d45.084233766736865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477f01fff731c7bb%3A0x2c16f3a73a3373b7!2smobilificio+al+Santuario+s.a.s.!5e1!3m2!1sit!2sit!4v1531983273973"
                    width="800" height="600" frameBorder="0"
                    style={{"height": "30rem", "width": "100%", "border": "none", "overflow": "hidden"}}
                    allowFullScreen/>
            </div>
            <img src={imgCartello} alt={"Insegna pubblicitaria mobilificio"}/>
        </div>
    </div>
}

export default Informazioni;