import React from "react"
import "./Marchi.min.css"

function importAll(r) {
    let images = {};
    r.keys().forEach((item) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
}

let logos = importAll(require.context('../../res/img/loghi_auto', false, /\.(png|gif|jpe?g|svg)$/));

function Marchi() {
    return <div className={"Marchi"}>
        <h2>I nostri marchi</h2>
        <div className={"ListaMarchi"}>
            {
                Object.keys(logos).map(k => {
                    return <img key={k} src={logos[k]} alt={"logo " + k.substr(0, k.indexOf("."))}/>
                })
            }
        </div>
    </div>
}

export default Marchi
