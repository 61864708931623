import React from 'react';
import './App.min.css';

import {Route, Switch} from "react-router-dom";
import Home from "../Home/Home";
import Informazioni from "../Informazioni/Informazioni";
import Navbar from "../Navbar/Navbar";
import Promotions from "../Promotions/Promotions";
import Outlet from "../Outlet/Outlet";
import Products from "../Products/Products";
import ProductById from "../ProductById/ProductById";
import PromotionById from "../PromotionById/PromotionById";
import {LandingCamere as LCa, LandingCucine as LCu, LandingSoggiorni as LS} from "../Landing/Landing";
import {Redirect, withRouter} from "react-router";

//TODO not found page

const baseTitle = "Mobilificio Al Santuario";
const basePath = "/app";

class App extends React.Component {
    render() {
        return <div className={"App"}>
            <Navbar/>

            <div className={"Content"}><Switch>
                <Route exact path={"/"} component={RedirectHome}/>
                <Route exact path={`${basePath}/`} component={Home}/>
                <Route exact path={`${basePath}/products`} component={RedirectProdotti}/>
                <Route exact path={`${basePath}/products/cat/:cat`} component={Products}/>
                <Route exact path={`${basePath}/products/cat/:cat/:subcat`} component={Products}/>
                <Route exact path={`${basePath}/products/id/:id`} component={ProductById}/>
                <Route exact path={`${basePath}/promo`} component={Promotions}/>
                <Route exact path={`${basePath}/promo/id/:id`} component={PromotionById}/>
                <Route exact path={`${basePath}/outlet`} component={RedirectOutlet}/>
                <Route exact path={`${basePath}/outlet/cat/:cat`} component={Outlet}/>
                <Route exact path={`${basePath}/outlet/cat/:cat/:subcat`} component={Outlet}/>
                <Route exact path={`${basePath}/info`} component={Informazioni}/>
                <Route exact path={`${basePath}/cucine`} component={LCu}/>
                <Route exact path={`${basePath}/camere`} component={LCa}/>
                <Route exact path={`${basePath}/soggiorni`} component={LS}/>
                <Route component={RedirectHome}/>
            </Switch></div>
        </div>
    }

}

function RedirectProdotti() {
    return <Redirect to={`${basePath}/products/cat/0`}/>;
}

function RedirectOutlet() {
    return <Redirect to={`${basePath}/outlet/cat/0`}/>;
}

function RedirectHome() {
    return <Redirect to={`${basePath}/`}/>
}

export default withRouter(App);
export {baseTitle, basePath};

