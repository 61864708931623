import React from "react";

import "../Outlet/Outlet.min.css"
import {basePath, baseTitle} from "../App/App";
import _ from "lodash";
import API from "../../API";
import {Product} from "../Products/Products";
import {Link} from "react-router-dom";
import {scrollToTop} from "../../common";

class Outlet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: this.props.match.params["cat"] ? parseInt(this.props.match.params["cat"], 10) : 0,
            subfilter: this.props.match.params["subcat"] ? parseInt(this.props.match.params["subcat"], 10) : null,
            categories: {},
            menuClosed: true
        }
    }

    componentWillMount() {
        document.title = "Outlet - " + baseTitle;
        scrollToTop()

        API.getCategories()
            .then(value => {
                this.setState(prevState => {
                    let res = {};
                    Object.assign(res, prevState);
                    value.forEach(v => {
                        res.categories[v.id] = v.tag;
                    });
                    return res;
                })
            })
            .catch(reason => {
                console.error(reason)
            });
    }

    render() {
        return <div className={`Outlet ${this.state.menuClosed ? "Closed" : ""}`}>
            <div className={`Filtri`}>
                <ul>
                    <Category id={0} category={"Tutto"} key={0} filter={this.state.filter}
                              subfilter={this.state.subfilter}
                              onClick={() => {
                                  this.setState({
                                      filter: 0,
                                      subfilter: null
                                  })
                              }}
                              onSubcatChange={(subcat) => this.setState({subfilter: subcat})}/>
                    {Object.keys(this.state.categories).map(ks => {
                        const k = parseInt(ks, 10);
                        const c = this.state.categories[k];
                        return <Category
                            id={k}
                            category={c}
                            key={k} filter={this.state.filter} subfilter={this.state.subfilter}
                            onClick={() => {
                                this.setState({
                                    filter: k,
                                    subfilter: null
                                })
                            }}
                            onSubcatChange={(subcat) => {
                                this.setState({subfilter: subcat})
                            }}
                        />
                    })}
                </ul>


            </div>
            <div className={"MenuButton noselect"}>
                <span onClick={() => {
                    this.setState(prevState => {
                        return {menuClosed: !prevState.menuClosed}
                    })
                }}>
                    ☰
                </span>
            </div>
            <OutletViewer filter={this.state.filter} subfilter={this.state.subfilter}/>
        </div>
    }
}

function Category(props) {
    const className = `noselect ${props.id === props.filter ? "Selected" : ""}`;

    const exceptCategory = [14, 17]; //Categorie escluse dall'avere il sottomenu

    const subcat = exceptCategory.includes(props.id) ? "" : <ul>
        <Subcategory id={props.id} subid={3} subfilter={props.subfilter}
                     onClick={() => {
                         props.onSubcatChange(3)
                     }}>Moderno</Subcategory>
        <Subcategory id={props.id} subid={2} subfilter={props.subfilter}
                     onClick={() => {
                         props.onSubcatChange(2)
                     }}>Classico</Subcategory>
    </ul>;

    if (props.id === props.filter) {
        return <li><Link to={`${basePath}/outlet/cat/${props.id}`}
                         className={className}
                         onClick={() => {
                             props.onClick()
                         }}>
            {props.category}
        </Link>
            {subcat}
        </li>

    } else {
        return <li><Link to={`${basePath}/outlet/cat/${props.id}`}
                         className={className}
                         onClick={() => {
                             props.onClick()
                         }}>
            {props.category}
        </Link></li>
    }

}

function Subcategory(props) {
    const className = `noselect ${props.subid === props.subfilter ? "Selected" : ""}`;

    return <li><Link to={`${basePath}/outlet/cat/${props.id}/${props.subid}`}
                     className={className}
                     onClick={() => {
                         props.onClick()
                     }}>
        {props.children}
    </Link></li>
}

class OutletViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: {}
        };
    }

    componentDidMount() {
        this.updateProducts();
    }

    updateProducts() {
        API.getOutletByCategorie([this.props.filter, this.props.subfilter])
            .then(valueProdutcs => {
                let products = {};
                valueProdutcs.forEach(v => {
                    v.images = {};
                    products[v.id] = v;
                });
                if (!_.isEqual(this.state.products, products)) {
                    this.setState(
                        {products: products});
                }
            })
            .catch(reason => {
                console.error(reason)
            })
    }

    componentDidUpdate() {
        this.updateProducts();
    }

    componentWillMount() {
        this.updateProducts();
    }

    render() {

        return <div className={"Content"}>
            <h1>Outlet</h1>
            <div className={"ProductsContainer"}>
                {this.state.products && Object.keys(this.state.products).length > 0 ?
                    Object.keys(this.state.products).map(k => {
                        return <Product id={k} key={k} title={this.state.products[k].titolo}/>
                    }) :
                    <div className={"NoProdotti"}>Nessun prodotto trovato</div>
                }
            </div>
        </div>
    }
}

export default Outlet;