import "./Landing.min.css"
import React from "react";

import imgCucineModerno from "../../res/img/splitCategorie/cucina_moderno.jpg";
import imgCucineClassico from "../../res/img/splitCategorie/cucina_classico.jpg";
import imgCameraModerno from "../../res/img/splitCategorie/camera_moderno.jpg";
import imgCameraClassico from "../../res/img/splitCategorie/camera_classico.jpg";
import imgSoggiornoModerno from "../../res/img/splitCategorie/soggiorno_moderno.jpg";
import imgSoggiornoClassico from "../../res/img/splitCategorie/soggiorno_classico.jpg";
import {Link} from "react-router-dom";
import {basePath} from "../App/App";


function Landing(props) {
    return <div className={"Landing"}>
        <Link to={props.urlL}>
            <div className={"ImgLeft"} style={{backgroundImage: `url(${props.imgL})`}}>
                <h2>{props.titleL}</h2>
            </div>
        </Link>
        <Link to={props.urlR}>
            <div className={"ImgRight"} style={{backgroundImage: `url(${props.imgR})`}}>
                <h2>{props.titleR}</h2>
            </div>
        </Link>
    </div>
}

function LandingCucine() {
    return <Landing imgL={imgCucineModerno} titleL={"Moderno"} urlL={`${basePath}/products/cat/4/3`}
                    imgR={imgCucineClassico} titleR={"Classico"} urlR={`${basePath}/products/cat/4/2`}/>;
}

function LandingCamere() {
    return <Landing imgL={imgCameraModerno} titleL={"Moderno"} urlL={`${basePath}/products/cat/15/3`}
                    imgR={imgCameraClassico} titleR={"Classico"} urlR={`${basePath}/products/cat/15/2`}/>;
}

function LandingSoggiorni() {
    return <Landing imgL={imgSoggiornoModerno} titleL={"Moderno"} urlL={`${basePath}/products/cat/5/3`}
                    imgR={imgSoggiornoClassico} titleR={"Classico"} urlR={`${basePath}/products/cat/5/2`}/>;
}

export default Landing;
export {LandingCucine, LandingCamere, LandingSoggiorni};