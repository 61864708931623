import React from "react";

import "./BannerPromozioni.min.css";

import API from "../../API";
import {Link} from "react-router-dom";
import {basePath} from "../App/App";

class BannerPromozioni extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            promotions: {}
        };
    }

    componentWillMount() {
        API.getPromozioni()
            .then(resPromo => {
                resPromo = resPromo
                    .filter(promo => {
                        return !promo.scadenza || (new Date() <= new Date(promo.scadenza * 1000))
                    })
                    .slice(0, 5);

                this.setState(
                    prevState => {
                        let res = {};
                        Object.assign(res, prevState);
                        resPromo.forEach(promo => {
                            res.promotions[promo.id] = promo;
                            if (!res.promotions[promo.id].images) {
                                res.promotions[promo.id].images = {};
                            }
                        });
                        return res;
                    },

                    () => {
                        resPromo.forEach(promo => {
                            API.getImmaginiPromozione(promo.id)
                                .catch(reason => {
                                    console.error(reason);
                                })
                                .then(imageIDs => {
                                    imageIDs.forEach(imageID => {
                                        API.getImageById(imageID)
                                            .then(img => {
                                                this.setState(prevState => {
                                                    let res = {};
                                                    Object.assign(res, prevState);
                                                    res.promotions[promo.id].images[img.id] = img.path;
                                                    return res;
                                                })
                                            })
                                            .catch(reason => {
                                                console.error(reason)
                                            });
                                    });
                                });
                        });

                    });
            })
            .catch(reason => {
                console.error(reason);
            });

    }

    render() {
        return <div className={"BannerPromozioni"}>
            <div><h2>Promozioni</h2></div>
            <ul>
                {
                    Object.values(this.state.promotions).map(promo => {
                        return <Promozione key={promo.id} title={promo.titolo} images={promo.images} id={promo.id}/>
                    })
                }
            </ul>
            <Link to={`${basePath}/promo`}>
                <div><h3 className={"More"}>Scopri tutte le promozioni</h3></div>
            </Link>
        </div>
    }
}


function Promozione(props) {
    return <Link to={`${basePath}/promo/id/${props.id}`} className={"Promozione noselect"}
                 style={{backgroundImage: `linear-gradient(rgba(0,0,0,0.0),rgba(0,0,0,0.30)),url(${Object.values(props.images)[0]})`}}>
        <h3>{props.title}</h3>
    </Link>
}

export default BannerPromozioni;
