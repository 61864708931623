import React from "react";
import "./ProductById.min.css";
import {basePath, baseTitle} from "../App/App";
import API from "../../API";
import {Redirect} from "react-router-dom";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"
import ReactMarkdown from "react-markdown";
import {scrollToTop} from "../../common";

// import ReactMarkdown from "react-markdown";


class ProductById extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: null,
            description: null,
            tags: {},
            invalid: null
        }
    }

    loadData() {
        const prodId = this.props.match.params["id"];
        API.getProdottoById(prodId)
            .then(value => {
                if (value.length === 0) {
                    this.setState({invalid: true})
                } else {
                    this.setState({
                        title: value[0]["titolo"],
                        description: value[0]["descrizione"],
                        invalid: false
                    });
                }
            })
            .catch(reason => {
                console.error(reason)
            });
        API.getImmaginiProdotto(prodId)
            .catch(reason => {
                console.error(reason)
            })
            .then(imageIDs => {
                Promise.all(imageIDs.map(imgID => {
                    return API.getImageById(imgID)
                }))
                    .then(images => {
                        this.setState({
                            images: images.map(img => {
                                return img.path
                            })
                        })
                    })
                    .catch(reason => {
                        console.error(reason)
                    });
                // imageIDs.forEach(imgID => {
                //     API.getImageById(imgID)
                //         .then(img => {
                //             images.push(img.path);
                //             this.setState({images: images});
                //         })
                //         .catch(reason => {
                //             console.error(reason)
                //         });
                // });
            });
    }

    componentWillMount() {
        this.loadData();
        scrollToTop()
    }

    componentDidUpdate() {
        document.title = `${this.state.title} - ${baseTitle}`
    }


    render() {
        switch (this.state.invalid) {
            case true:
                return <Redirect to={`${basePath}/404`}/>;
            case null:
                return "LOADING";
            case false:
                return <div>
                    <div className={"ProductById"}>
                        <div className={"ProductContent"}>
                            <h1>{this.state.title}</h1>
                            <ReactMarkdown source={this.state.description}/>
                            {this.state.images && this.state.images.length > 0 ? <div className={"ProductSlideshow"}>
                                <Images images={this.state.images} title={this.state.title}/>
                            </div> : <div className={"ProductSlideshow"}/>}

                        </div>
                    </div>
                    {/*{this.printState()}*/}
                </div>;
            default:
                console.error("Error while loading the products data")
        }
    }

    printState() {
        return <pre>{JSON.stringify(this.state, null, 2)}</pre>
    }
}

function Images(props) {
    if (props.images && props.images.length > 0) {
        return <Carousel
            autoPlay={true}
            // centerMode={true}
            dynamicHeight={true}
            infiniteLoop={true}
            interval={5000}
            showArrows={true}
            showIndicators={true}
            showThumbs={true}
            statusFormatter={(cur, tot) => {
                return `${cur} di ${tot}`
            }}
            transitionTime={400}
            useKeyboardArrows={true}
        >
            {props.images.map((img, k) => {
                return <div key={k}>
                    <img src={img} alt={`${props.title} - ${k + 1}`}/>
                </div>
            })}
        </Carousel>
        // return <Carousel
        //     autoplay={true}
        //     autoplayInterval={5000}
        //     // autoGenerateStyleTag={false}
        //     dragging={false}
        //     heightMode={"current"}
        //     slidesToShow={Math.min(3, props.images.length)}
        // >
        //     {props.images.map((img, k) => {
        //         return <img src={img} key={k} alt={`${props.title} - ${k+1}`}/>
        //     })}
        // </Carousel>
    } else {
        return <div/>
    }
}

export default ProductById;