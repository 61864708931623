import React from "react";
import {Link} from "react-router-dom";

import "./BannerCategorie.min.css";

import imgCucina from "../../res/img/categorie/cucina.jpg";
import imgUfficio from "../../res/img/categorie/ufficio.jpg";
import imgSoggiorno from "../../res/img/categorie/soggiorno.jpg";
import imgBagno from "../../res/img/categorie/bagno.jpg";
import imgCamera from "../../res/img/categorie/camera.jpg";
import imgCameretta from "../../res/img/categorie/cameretta.jpg";
import imgPoltroneRelax from "../../res/img/categorie/poltrone-relax.jpg";
import imgDivani from "../../res/img/categorie/divani.jpg";
import imgMaterassi from "../../res/img/categorie/materassi.jpg";
import API from "../../API";
import {basePath} from "../App/App";

const imageMap = {
    "cucine": [imgCucina],
    "bagni": [imgBagno],
    "soggiorni": [imgSoggiorno],
    "uffici": [imgUfficio],
    "camere": [imgCamera],
    "camerette": [imgCameretta],
    "poltrone relax": [imgPoltroneRelax],
    "divani": [imgDivani],
    "materassi": [imgMaterassi]
};

class BannerCategorie extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: {}
        };
    }

    componentWillMount() {
        API.getCategories(true)
            .then(value => {
                this.setState({categories: value})
            })
            .catch(reason => {
                console.error(reason)
            });
    }

    render() {
        return <div className={"BannerCategorie"}>
            <div><h2>Categorie</h2></div>
            <ul>
                <BoxCategoriaURL
                    key={"cucine"} title={"cucine"}
                    images={imageMap["cucine"]}
                    url={"/cucine"}
                />
                <BoxCategoriaURL
                    key={"soggiorni"} title={"soggiorni"}
                    images={imageMap["soggiorni"]}
                    url={"/soggiorni"}
                />
                <BoxCategoriaURL
                    key={"camere"} title={"camere"}
                    images={imageMap["camere"]}
                    url={"/camere"}
                />
                {
                    Object.values(this.state.categories)
                        .filter((v) => {
                            return !["cucine", "soggiorni", "camere"].includes(v.tag)
                        })
                        .sort((a, b) => {
                            a = a.tag.toLowerCase();
                            b = b.tag.toLowerCase();
                            const ord = ["cucine", "soggiorni", "divani", "camere", "camerette", "poltrone relax", "bagni", "materassi"];
                            if (ord.includes(a) && ord.includes(b)) {
                                return ord.indexOf(a) > ord.indexOf(b)
                            } else return !ord.includes(a);
                        }).map(cat => {
                        return <BoxCategoriaId key={cat.id} title={cat.tag} images={imageMap[cat.tag]} catId={cat.id}/>
                    })
                }
            </ul>
        </div>
    }
}

function BoxCategoriaId(props) {
    return BoxCategoriaURL({
        url: `/products/cat/${props.catId}`,
        ...props
    });
}

function BoxCategoriaURL(props) {
    return <Link to={`${basePath}${props.url}`} className={"Categoria noselect"}
                 style={{
                     backgroundImage: `linear-gradient(rgba(0,0,0,0.0),rgba(0,0,0,0.30))${props.images ? ",url(" + Object.values(props.images)[0] + ")" : ""}`,
                 }}>
        <li><h3>{props.title}</h3></li>
    </Link>
}

export default BannerCategorie;
